.LogoWall_container__oRzqn {
}

.LogoWall_content__0TVmd {
  grid-column: start / end;
}

.LogoWall_layout__WPqSz {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--spacer-16) var(--grid-gutter-size);
}

@media (min-width: 768px) {

.LogoWall_layout__WPqSz {
    padding: 0;
    gap: var(--spacer-16) 0
}
  }

@media (min-width: 1240px) {

.LogoWall_layout__WPqSz {
    padding: 0;
    gap: var(--spacer-16) var(--spacer-10)
}
  }

.LogoWall_desktopLayout__5BJ38 {
  display: flex;
}

.LogoWallRow_row___NlgR {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.LogoWallRow_duplicate__h1Wun {
  display: flex;
  flex-flow: row nowrap;
  margin-right: var(--spacer-84);
  gap: var(--spacer-84);
  place-items: center;
}

@media (min-width: 768px) {

.LogoWallRow_duplicate__h1Wun {
    margin-right: var(--spacer-96);
    gap: var(--spacer-96)
}
  }

.LogoWallRow_icon__J0ork {
  --image-aspect-ratio: 2.81;
  --graphic-scale: 0.75;
}

@media (min-width: 768px) {

.LogoWallRow_icon__J0ork {
    --graphic-scale: 1
}
  }

.MediaLayer_container___RFDD {
  position: relative;
  width: 100%;
  height: 100%;
}

.MediaLayer_base__IeX4H {
  position: static;
}

.MediaLayer_variant__5cUaw {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.MediaLayer_base__IeX4H,
.MediaLayer_variant__5cUaw {
  z-index: 0;
  visibility: hidden;
}

.MediaLayer_base__IeX4H.MediaLayer_active__Pb2Q4, .MediaLayer_variant__5cUaw.MediaLayer_active__Pb2Q4 {
    z-index: 1;
    visibility: visible;
  }

.MediaLayer_base__IeX4H figure, .MediaLayer_variant__5cUaw figure {
    min-height: 100%;
  }

.Device_container__SZkk_ {
  position: absolute;
  z-index: var(--z-index-0);
  left: 0;
  width: 100%;
  height: 100%;
}

.Device_mediaWrapper__TPfiK {
  position: relative;
  grid-column: 2 / span 4;
}

@media (min-width: 768px) {

.Device_mediaWrapper__TPfiK {
    position: sticky;
    top: -50rem;
    height: 100vh;
    grid-column: 6 / span 4
}
  }

.Device_base__uN6Uk,
.Device_variant__vppH0 {
  /**
   * Note on the transforms here and the fixed values. This is a bit of a bespoke
   * moment and the values are driven by the arm asset and are used to align our
   * video to the the device cutout relative to the top/center of the image.
   *
   * If the arm asset updates, or the arm hotspot moves, these values need to
   * be adjusted. Currently the video asset does not have perfect aspect ratio
   * compared to the arm device cutout and can not be placed perfeclty within
   * the cutout region.
   */
  overflow: hidden;
  padding: 2px;
  border-radius: 4rem;
  background-color: black;
  transform: scale(0.58) translateX(2px) translateY(calc(4px + var(--img-scale)*120px));
  transform-origin: top center;
  visibility: hidden;
}

.Device_base__uN6Uk.Device_visible__W0QG3, .Device_variant__vppH0.Device_visible__W0QG3 {
    z-index: 1;
    visibility: visible;
  }

@media (min-width: 768px) {

.Device_base__uN6Uk,
.Device_variant__vppH0 {
    border-radius: 3rem;
    transform: scale(0.8) translateX(2px) translateY(calc(var(--img-scale)*50px))
}
  }

@media (min-width: 1240px) {

.Device_base__uN6Uk,
.Device_variant__vppH0 {
    transform: scale(0.8) translateX(2px) translateY(calc(var(--img-scale)*50px))
}
  }

.Device_base__uN6Uk {
  visibility: hidden;
}

.Device_variant__vppH0 {
  position: absolute;
}

.DeviceThemeToggle_container__ZSdgv {
  position: absolute;
  z-index: var(--z-index-4);
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 20px, 0);
  transition:
    transform var(--duration-200ms) var(--ease-basic-butter),
    opacity var(--duration-200ms) linear;
}

  @media (min-width: 768px) {.DeviceThemeToggle_container__ZSdgv {
    display: grid
}
  }

  .DeviceThemeToggle_container__ZSdgv.DeviceThemeToggle_isVisible__zlWT0 {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition:
      transform var(--duration-500ms) var(--ease-basic-butter),
      opacity var(--duration-500ms) linear;
  }

.DeviceThemeToggle_toggleWrapper__ZmvXW {
  position: relative;
  grid-column: 1 / span 1;
}

.DeviceThemeToggle_toggleTrack__sscw9 {
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  height: 100vh;
}

.DeviceThemeToggle_toggle__AdmsY {
  position: absolute;
  top: calc(50% - 42px);
  right: 0;
  display: flex;
  width: 44px;
  height: 84px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  border: 1px solid rgb(209 209 251 / 20%);
  border-radius: 100px;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  pointer-events: all;
}

@media (min-width: 1240px) {

.DeviceThemeToggle_toggle__AdmsY {
    right: 10%
}
  }

.DeviceThemeToggle_toggleContainer__hg2vb {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  /* padding aligns the sun & moon icons so they are centered within the indicator */
  padding-top: 8px;
  padding-bottom: 10px;
  border-radius: 100px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: radial-gradient(713.82% 279.6% at 10% -45.83%, rgb(125 125 204 / 15%) 0%, rgb(157 157 255 / 0%) 100%);
  gap: 4px;
}

.DeviceThemeToggle_indicator__Nc_eS {
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  width: 35px;
  height: 35px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border: 0.8px rgb(45 47 61 / 100%) solid;
  border-radius: 100px;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background-color: rgb(42 44 57 / 100%);
  box-shadow: 0 0 16px rgb(0 0 0 / 50%);
  gap: 4px;
  transition: top 0.3s var(--ease-embellishment);
}

.DeviceThemeToggle_indicator__Nc_eS.DeviceThemeToggle_light___GGLT {
    top: calc(50% + 2px);
  }

.DeviceThemeToggle_icons__feFZv {
  z-index: var(--z-index-2);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  pointer-events: none;
}

.DeviceThemeToggle_icon__qak_2 {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.3s var(--ease-embellishment);
}

.DeviceThemeToggle_activeIcon__f2ohH {
  opacity: 1;
}

.Card_card__lhxXW {
  --card-stack-offset: calc(-2.6em + (var(--card-index, 0) + 1)*-0.1em);
  position: relative;
}

.Card_media__ZiD0E {
  --media-start: start;
  --media-span: var(--grid-number-of-columns);
  position: relative;
  z-index: var(--z-index-1);
  height: 448px;
  min-height: 448px;
  border-radius: var(--border-radius-10);
  margin-top: var(--spacer-64);
  grid-column: var(--media-start) / span var(--media-span);
  place-content: center;
  text-align: center;
}

@media (min-width: 768px) {

.Card_media__ZiD0E {
    --media-start: start;
    --media-span: 7;
    height: auto;
    min-height: 480px;
    margin-bottom: 0;
    opacity: 1;
    transform: translate3d(0, calc(var(--card-next-progress, 0)*var(--card-stack-offset)*var(--reverse-index)), 0);
    transition: transform 0.2s ease-out
}
  }

@media (min-width: 768px) {
    .Card_mediaOutro__2GciI::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-5);
      background-color: black;
      content: '';
      opacity: calc(var(--card-next-progress, 0)*0.6);
      transform: scale(calc(1 - var(--card-next-progress, 1)*(0.05*var(--reverse-index))));
      transition: transform 0.2s ease-out;
    }
  }

.Card_mediaScaler__o7GVe {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  /* border: 1px solid rgba(189, 189, 244, 0.2); */
  border: 1px solid var(--color-alpha-light-10);
  border-radius: var(--border-radius-5);
}

@media (min-width: 768px) {

.Card_mediaScaler__o7GVe {
    transform: scale(calc(1 - var(--card-next-progress, 1)*(0.05*var(--reverse-index))));
    transition: transform 0.2s ease-out
}
  }

.Card_backgroundMediaWrapper__zr1wx {
  /* overflow: hidden; */
  height: 100%;
  background-color: black;
}

.Card_backgroundMediaContainer__8pwds {
  --parallax-margin: 48px;
  --media-scale: 1.3;
  overflow: hidden;
  height: 100%;
  border-radius: var(--border-radius-5);
  background-color: black;
}

@media (min-width: 768px) {

.Card_backgroundMediaContainer__8pwds {
    transform: scale(var(--media-scale)) translateY(calc(var(--parallax-margin) - var(--card-enter-progress, 0)*var(--parallax-margin) - var(--card-next-progress, 0)*var(--parallax-margin)));

    --media-scale: 1.2
}
  }

.Card_foregroundMediaWrapper__7YsaA {
  --media-width: 100%;
  --media-height: auto;
  --media-top: 0;
  --media-side-offset: 0;
  position: absolute;
  top: var(--media-top);
  overflow: hidden;
  width: var(--media-width);
  height: var(--media-height);
  border-radius: var(--border-radius-5);
}

.Card_bleedsleft__IzhqW .Card_foregroundMediaWrapper__7YsaA {
    right: var(--media-side-offset);
  }

.Card_bleedsright__9SVR6 .Card_foregroundMediaWrapper__7YsaA {
    left: var(--media-side-offset);
  }

.Card_foregroundMediaContainer__g3D2j {
  transform-origin: center left;
}

.Card_content__kamNV {
  --content-start: start;
  --content-span: var(--grid-number-of-columns);
  z-index: var(--z-index-1);
  display: block;
  margin-top: var(--spacer-32);
  grid-column: var(--content-start) / span var(--content-span);
  text-align: left;
}

@media (min-width: 768px) {

.Card_content__kamNV {
    --content-start: 10;
    --content-span: 4;
    display: flex;
    flex-flow: column;
    margin-top: var(--spacer-64);
    place-content: center
}
  }

@media (min-width: 768px) {

.Card_content__kamNV .Card_contentText__rO3Cd {
      padding: 4rem;
      margin-right: -4rem;
      margin-left: -4rem;
      background: radial-gradient(rgb(0 0 0 / 80%) 40%, rgb(0 0 0 / 0%) 70%)
  }
    }

.CardWrapper_cardWrapper__3R9u5 {
  --section-vertical-padding: calc(var(--spacer-96)/2);
  --active-progress: 0;
  position: relative;
  top: 0;
  display: flex;
  order: -1;
  padding: 0;
  opacity: calc(min(0.3, var(--card-enter-progress, 0))/0.3 - (max(0.8, var(--card-next-progress, 0)) - 0.8)/0.2);
}

  .CardWrapper_cardWrapper__3R9u5:first-child {
    opacity: calc(1 - (max(0.7, var(--card-next-progress, 0)) - 0.7)/0.3);
  }

  @media (min-width: 768px) {

  .CardWrapper_cardWrapper__3R9u5:first-child {
      opacity: 1
  }
    }

  @media (min-width: 768px) {.CardWrapper_cardWrapper__3R9u5 {
    position: sticky;
    left: 0;
    height: 100vh;
    order: 1;
    opacity: 1;
    transform: translate3d(0, 0, 0)
}
  }

  @media (min-width: 1240px) {.CardWrapper_cardWrapper__3R9u5 {
    --section-vertical-padding: calc(var(--spacer-240)/2)
}
  }

  .CardWrapper_cardWrapper__3R9u5.CardWrapper_active__FjkDF {
    --active-progress: 1;
  }

  @media (min-width: 768px) {

  .CardWrapper_cardWrapper__3R9u5:last-of-type .CardWrapper_content__YgeTW {
        opacity: 1;
        transform: translate3d(0, calc((1 - var(--card-enter-progress, 0))*40rem), 0);
        transition: transform 0.2s ease-out
    }
      }

  @media (min-width: 768px) {

  .CardWrapper_cardWrapper__3R9u5 .CardWrapper_content__YgeTW {
      opacity: calc(1 - var(--card-leave-progress, 0));
      transform: translate3d(calc(var(--card-next-progress, 0)*-32px), calc((1 - var(--card-enter-progress, 0))*40rem + (var(--card-next-progress, 0))*-10rem), 0) scale(calc(1 - var(--card-next-progress, 0)*0.05));
      transform-origin: top left;
      transition:
        transform 0.2s ease-out,
        opacity 0.2s ease-out
  }
    }

.InteractiveStack_container__7HLUV {
  position: relative;

  /* use the scale of the image to control margin so that the stack container lines up with the devices */
  margin-top: calc(10rem + var(--img-scale)*200rem);
}

  @media (min-width: 768px) {.InteractiveStack_container__7HLUV {
    /* align the stack container to the device container using the margin. this alignment allows both container to become sticky at the same time */
    margin-top: 54rem
}
  }

.InteractiveStack_wrapper__LMw3S {
  position: relative;
  display: block;
}

@media (min-width: 768px) {

.InteractiveStack_cardWrapper__7xEq8 {
    align-items: center
}
  }

.Snow_wrapper__syc0r {
  position: absolute;
  z-index: var(--z-index-0, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.Snow_container__fNULA {
  position: sticky;
  z-index: var(--z-index-0, 0);
  top: 0;
  width: 100%;
  height: 100vh;
  mix-blend-mode: screen;
}

.Snow_overlay__LiWm0 {
  position: sticky;
  z-index: var(--z-index-1, 1);
  top: 0;
  width: 100%;
  height: 100vh;
  margin-top: -100vh;
  background: linear-gradient(rgb(0 0 0 / 0%) 60%, rgb(0 0 0 / var(--bottom-gradient-opacity, 0)) 85%);
}

.StandoutDevice_module__VgTf_ {
  position: relative;
  display: block;
  padding-top: var(--spacer-84);
}

  @media (min-width: 768px) {.StandoutDevice_module__VgTf_ {
    padding-top: var(--spacer-124);

    /* disable the module padding since the each card is 100vh we want to module to
    end where the last card ends */
    --module-padding-bottom: 0
}
  }

.StandoutDevice_container__fs_IR {
  width: 100%;
}

.StandoutDevice_titleWrapper__kZ2Sg {
  z-index: 0;
  margin-top: var(--spacer-48);
  will-change: transform, opacity;
}

.StandoutDevice_deviceWrapper__csY89 {
  height: 100%;
  margin-top: var(--spacer-40);
  pointer-events: none;
  transform-origin: top center;
  will-change: transform, opacity;
}

@media (min-width: 768px) {

.StandoutDevice_deviceWrapper__csY89 {
    position: absolute
}
  }

.StandoutDevice_deviceContainer__O6L1G {
  grid-column: 2 / span 4;
}

@media (min-width: 768px) {

.StandoutDevice_deviceContainer__O6L1G {
    position: sticky;
    top: 0;
    height: 100vh
}
  }

@media (min-width: 768px) {

.StandoutDevice_deviceContainer__O6L1G {
    /* control the alignment of the arm within the sticky container */
    margin-top: 50rem;
    grid-column: 6 / span 4
}
  }

.StandoutDevice_deviceContainer__O6L1G .StandoutDevice_enhMedia__EJXXA {
    position: relative;
  }

@media (min-width: 768px) {

.StandoutDevice_deviceContainer__O6L1G .StandoutDevice_enhMedia__EJXXA {
      transform: translate3d(0, -50rem, 0)
  }
    }

.StandoutDevice_deviceContainer__O6L1G .StandoutDevice_armMediaContainer__kFHlI {
    position: absolute;
    top: var(--img-top);
    left: var(--img-left);
    width: var(--img-width);
    aspect-ratio: 1;
  }

.StandoutDevice_deviceContainer__O6L1G .StandoutDevice_armMedia__f9jWd {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.StandoutDevice_stackWrapper__lqL2f {
  margin-top: calc(55rem + var(--img-scale, 0)*90rem);
  grid-column: 1 / span 16;
}

@media (min-width: 768px) {

.StandoutDevice_stackWrapper__lqL2f {
    margin-top: var(--spacer-40);
    margin-bottom: calc(var(--spacer-124)*-1)
}
  }

.StandoutDevice_logoWall__r9595 {
  position: absolute;
  z-index: var(--z-index-neg);
  margin-top: calc(10rem + var(--container-height, 0));
}

@media (min-width: 768px) {

.StandoutDevice_logoWall__r9595 {
    margin-top: 72rem
}
  }

.StandoutMosaic_container__rvD8F {
  --module-spacing-top: var(--spacer-64);
  --module-spacing-bottom: var(--spacer-64);
  position: relative;
  padding-top: var(--module-spacing-top);
  padding-bottom: var(--module-spacing-bottom);
}

  @media (min-width: 768px) {.StandoutMosaic_container__rvD8F {
    --module-spacing-bottom: 0;
    margin-bottom: var(--spacer-32)
}
  }

.StandoutMosaic_title__PXsyw {
  grid-column: start / end;
}

.StandoutMosaic_mosaicWrapper__6suUn {
  position: relative;

  --mosaic-width: 100%;
  --mosaic-gap: var(--spacer-10);
  --column-width-4: (var(--mosaic-width) / var(--grid-number-of-columns) * 4);
  --column-width-2: (var(--mosaic-width) / var(--grid-number-of-columns) * 2);
}

@media (min-width: 768px) {

.StandoutMosaic_mosaicWrapper__6suUn {
    --mosaic-gap: var(--spacer-16);
}
  }

.StandoutMosaic_mosaicWrapper__6suUn {
  display: grid;
  width: calc(var(--column-width-4) + var(--mosaic-width) + var(--grid-gutter-size));
  align-items: center;
  justify-content: center;
  margin-left: calc(var(--column-width-2)*-1 - var(--grid-gutter-size)/2);
  grid-column-gap: var(--mosaic-gap);
  -moz-column-gap: var(--mosaic-gap);
       column-gap: var(--mosaic-gap);
  grid-column: start / end;
  grid-template-columns: repeat(20, 1fr);
  pointer-events: none;
}

.StandoutMosaic_cardColumn___mTx9 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 16rem;
  grid-column: span 5;
  row-gap: var(--mosaic-gap);
}

.StandoutMosaic_card__0p5G0 {
  overflow: hidden;
  border-radius: 24px;
  aspect-ratio: 1 / 1;
  background-color: #090a18;
}

.StandoutMosaic_deviceWrapper__8FP9G {
  /* device scale is calculated from the figma breakpoints */
  --device-scale: 0.54;
  position: absolute;
  z-index: 2;
  top: 5rem;
  left: calc(50% - var(--device-width)/2);
  width: var(--device-width);
  height: var(--device-height);
  filter: drop-shadow(20px 14px 38px rgb(0 0 0 / 45%)) drop-shadow(52px 75px 125px rgb(0 0 0 / 85%));

  /* the width & height dimensions of the device are defined in figma. this calculates the final size including the device scale across breakpoints */
  --device-width: calc(var(--device-scale)*333px);
  --device-height: calc(var(--device-scale)*692px);
}

@media (min-width: 768px) {

.StandoutMosaic_deviceWrapper__8FP9G {
    --device-scale: 1;
    top: -16rem
}
  }

.StandoutMosaic_deviceMedia__BlG_n {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--device-width);
  height: var(--device-height);
}


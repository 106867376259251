.module {
  position: relative;
  display: block;
  padding-top: var(--spacer-84);

  @media (--md) {
    padding-top: var(--spacer-124);

    /* disable the module padding since the each card is 100vh we want to module to
    end where the last card ends */
    --module-padding-bottom: 0;
  }
}

.container {
  width: 100%;
}

.titleWrapper {
  composes: grid from '~g';
  z-index: 0;
  margin-top: var(--spacer-48);
  will-change: transform, opacity;
}

.deviceWrapper {
  composes: grid from '~g';
  height: 100%;
  margin-top: var(--spacer-40);
  pointer-events: none;
  transform-origin: top center;
  will-change: transform, opacity;

  @media (--md) {
    position: absolute;
  }
}

.deviceContainer {
  grid-column: 2 / span 4;

  @media (--md) {
    position: sticky;
    top: 0;
    height: 100vh;
  }

  @media (--md) {
    /* control the alignment of the arm within the sticky container */
    margin-top: 50rem;
    grid-column: 6 / span 4;
  }

  .enhMedia {
    position: relative;

    @media (--md) {
      transform: translate3d(0, -50rem, 0);
    }
  }

  .armMediaContainer {
    position: absolute;
    top: var(--img-top);
    left: var(--img-left);
    width: var(--img-width);
    aspect-ratio: 1;
  }

  .armMedia {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.stackWrapper {
  margin-top: calc(55rem + var(--img-scale, 0) * 90rem);
  grid-column: 1 / span 16;

  @media (--md) {
    margin-top: var(--spacer-40);
    margin-bottom: calc(-1 * var(--spacer-124));
  }
}

.logoWall {
  position: absolute;
  z-index: var(--z-index-neg);
  margin-top: calc(10rem + var(--container-height, 0));

  @media (--md) {
    margin-top: 72rem;
  }
}

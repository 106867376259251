.cardWrapper {
  --section-vertical-padding: calc(var(--spacer-96) / 2);
  --active-progress: 0;
  position: relative;
  top: 0;
  display: flex;
  order: -1;
  padding: 0;
  opacity: calc((min(0.3, var(--card-enter-progress, 0)) / 0.3) - (((max(0.8, var(--card-next-progress, 0)) - 0.8) / 0.2)));

  &:first-child {
    opacity: calc(1 - (((max(0.7, var(--card-next-progress, 0)) - 0.7) / 0.3)));

    @media (--md) {
      opacity: 1;
    }
  }

  @media (--md) {
    position: sticky;
    left: 0;
    height: 100vh;
    order: 1;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @media (--lg) {
    --section-vertical-padding: calc(var(--spacer-240) / 2);
  }

  &.active {
    --active-progress: 1;
  }

  &:last-of-type {
    .content {
      @media (--md) {
        opacity: 1;
        transform: translate3d(0, calc((1 - var(--card-enter-progress, 0)) * 40rem), 0);
        transition: transform 0.2s ease-out;
      }
    }
  }

  .content {
    @media (--md) {
      opacity: calc(1 - var(--card-leave-progress, 0));
      transform: translate3d(calc(var(--card-next-progress, 0) * -32px), calc((1 - var(--card-enter-progress, 0)) * 40rem + (var(--card-next-progress, 0)) * -10rem), 0) scale(calc(1 - var(--card-next-progress, 0) * 0.05));
      transform-origin: top left;
      transition:
        transform 0.2s ease-out,
        opacity 0.2s ease-out;
    }
  }
}

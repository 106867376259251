.row {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.duplicate {
  display: flex;
  flex-flow: row nowrap;
  margin-right: var(--spacer-84);
  gap: var(--spacer-84);
  place-items: center;

  @media (--md) {
    margin-right: var(--spacer-96);
    gap: var(--spacer-96);
  }
}

.icon {
  --image-aspect-ratio: 2.81;
  --graphic-scale: 0.75;

  @media (--md) {
    --graphic-scale: 1;
  }
}

.card {
  --card-stack-offset: calc(-2.6em + (var(--card-index, 0) + 1) * -0.1em);
  composes: grid from '~g';
  position: relative;
}

.media {
  --media-start: start;
  --media-span: var(--grid-number-of-columns);
  position: relative;
  z-index: var(--z-index-1);
  height: 448px;
  min-height: 448px;
  border-radius: var(--border-radius-10);
  margin-top: var(--spacer-64);
  grid-column: var(--media-start) / span var(--media-span);
  place-content: center;
  text-align: center;

  @media (--md) {
    --media-start: start;
    --media-span: 7;
    height: auto;
    min-height: 480px;
    margin-bottom: 0;
    opacity: 1;
    transform: translate3d(0, calc(var(--card-next-progress, 0) * var(--card-stack-offset) * var(--reverse-index)), 0);
    transition: transform 0.2s ease-out;
  }
}

.mediaOutro {
  @media (--md) {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-5);
      background-color: black;
      content: '';
      opacity: calc(var(--card-next-progress, 0) * 0.6);
      transform: scale(calc(1 - var(--card-next-progress, 1) * (0.05 * var(--reverse-index))));
      transition: transform 0.2s ease-out;
    }
  }
}

.mediaScaler {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  /* border: 1px solid rgba(189, 189, 244, 0.2); */
  border: 1px solid var(--color-alpha-light-10);
  border-radius: var(--border-radius-5);

  @media (--md) {
    transform: scale(calc(1 - var(--card-next-progress, 1) * (0.05 * var(--reverse-index))));
    transition: transform 0.2s ease-out;
  }
}

.backgroundMediaWrapper {
  /* overflow: hidden; */
  height: 100%;
  background-color: black;
}

.backgroundMediaContainer {
  --parallax-margin: 48px;
  --media-scale: 1.3;
  overflow: hidden;
  height: 100%;
  border-radius: var(--border-radius-5);
  background-color: black;

  @media (--md) {
    transform: scale(var(--media-scale)) translateY(calc(var(--parallax-margin) - (var(--card-enter-progress, 0) * var(--parallax-margin) + var(--card-next-progress, 0) * var(--parallax-margin))));

    --media-scale: 1.2;
  }
}

.foregroundMediaWrapper {
  --media-width: 100%;
  --media-height: auto;
  --media-top: 0;
  --media-side-offset: 0;
  position: absolute;
  top: var(--media-top);
  overflow: hidden;
  width: var(--media-width);
  height: var(--media-height);
  border-radius: var(--border-radius-5);

  .bleedsleft & {
    right: var(--media-side-offset);
  }

  .bleedsright & {
    left: var(--media-side-offset);
  }
}

.foregroundMediaContainer {
  transform-origin: center left;
}

.content {
  --content-start: start;
  --content-span: var(--grid-number-of-columns);
  z-index: var(--z-index-1);
  display: block;
  margin-top: var(--spacer-32);
  grid-column: var(--content-start) / span var(--content-span);
  text-align: left;

  @media (--md) {
    --content-start: 10;
    --content-span: 4;
    display: flex;
    flex-flow: column;
    margin-top: var(--spacer-64);
    place-content: center;
  }

  .contentText {
    @media (--md) {
      padding: 4rem;
      margin-right: -4rem;
      margin-left: -4rem;
      background: radial-gradient(rgb(0 0 0 / 80%) 40%, rgb(0 0 0 / 0%) 70%);
    }
  }
}

.container {
  composes: grid from '~g';
}

.content {
  grid-column: start / end;
}

.layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--spacer-16) var(--grid-gutter-size);

  @media (--md) {
    padding: 0;
    gap: var(--spacer-16) 0;
  }
  @media (--lg) {
    padding: 0;
    gap: var(--spacer-16) var(--spacer-10);
  }
}

.desktopLayout {
  display: flex;
}

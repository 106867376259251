.container {
  composes: grid from '~g';
  position: absolute;
  z-index: var(--z-index-4);
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 20px, 0);
  transition:
    transform var(--duration-200ms) var(--ease-basic-butter),
    opacity var(--duration-200ms) linear;

  @media (--md) {
    display: grid;
  }

  &.isVisible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition:
      transform var(--duration-500ms) var(--ease-basic-butter),
      opacity var(--duration-500ms) linear;
  }
}

.toggleWrapper {
  position: relative;
  grid-column: 1 / span 1;
}

.toggleTrack {
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  height: 100vh;
}

.toggle {
  position: absolute;
  top: calc(50% - 42px);
  right: 0;
  display: flex;
  width: 44px;
  height: 84px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  border: 1px solid rgb(209 209 251 / 20%);
  border-radius: 100px;
  backdrop-filter: blur(8px);
  pointer-events: all;

  @media (--lg) {
    right: 10%;
  }
}

.toggleContainer {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  /* padding aligns the sun & moon icons so they are centered within the indicator */
  padding-top: 8px;
  padding-bottom: 10px;
  border-radius: 100px;
  backdrop-filter: blur(10px);
  background: radial-gradient(713.82% 279.6% at 10% -45.83%, rgb(125 125 204 / 15%) 0%, rgb(157 157 255 / 0%) 100%);
  gap: 4px;
}

.indicator {
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
  width: 35px;
  height: 35px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border: 0.8px rgb(45 47 61 / 100%) solid;
  border-radius: 100px;
  backdrop-filter: blur(20px);
  background-color: rgb(42 44 57 / 100%);
  box-shadow: 0 0 16px rgb(0 0 0 / 50%);
  gap: 4px;
  transition: top 0.3s var(--ease-embellishment);

  &.light {
    top: calc(50% + 2px);
  }
}

.icons {
  z-index: var(--z-index-2);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  pointer-events: none;
}

.icon {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 0.3s var(--ease-embellishment);
}

.activeIcon {
  opacity: 1;
}

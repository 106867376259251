.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.base {
  position: static;
}

.variant {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.base,
.variant {
  z-index: 0;
  visibility: hidden;

  &.active {
    z-index: 1;
    visibility: visible;
  }

  figure {
    min-height: 100%;
  }
}

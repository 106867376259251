.container {
  --module-spacing-top: var(--spacer-64);
  --module-spacing-bottom: var(--spacer-64);
  composes: grid from '~g';
  position: relative;
  padding-top: var(--module-spacing-top);
  padding-bottom: var(--module-spacing-bottom);

  @media (--md) {
    --module-spacing-bottom: 0;
    margin-bottom: var(--spacer-32);
  }
}

.title {
  grid-column: start / end;
}

.mosaicWrapper {
  position: relative;

  --mosaic-width: 100%;
  --mosaic-gap: var(--spacer-10);
  --column-width-4: (var(--mosaic-width) / var(--grid-number-of-columns) * 4);
  --column-width-2: (var(--mosaic-width) / var(--grid-number-of-columns) * 2);

  @media (--md) {
    --mosaic-gap: var(--spacer-16);
  }
  display: grid;
  width: calc(var(--column-width-4) + (var(--mosaic-width) + (var(--grid-gutter-size))));
  align-items: center;
  justify-content: center;
  margin-left: calc(-1 * var(--column-width-2) - var(--grid-gutter-size) / 2);
  column-gap: var(--mosaic-gap);
  grid-column: start / end;
  grid-template-columns: repeat(20, 1fr);
  pointer-events: none;
}

.cardColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 16rem;
  grid-column: span 5;
  row-gap: var(--mosaic-gap);
}

.card {
  overflow: hidden;
  border-radius: 24px;
  aspect-ratio: 1 / 1;
  background-color: #090a18;
}

.deviceWrapper {
  /* device scale is calculated from the figma breakpoints */
  --device-scale: 0.54;
  position: absolute;
  z-index: 2;
  top: 5rem;
  left: calc(50% - var(--device-width) / 2);
  width: var(--device-width);
  height: var(--device-height);
  filter: drop-shadow(20px 14px 38px rgb(0 0 0 / 45%)) drop-shadow(52px 75px 125px rgb(0 0 0 / 85%));

  /* the width & height dimensions of the device are defined in figma. this calculates the final size including the device scale across breakpoints */
  --device-width: calc(var(--device-scale) * 333px);
  --device-height: calc(var(--device-scale) * 692px);

  @media (--md) {
    --device-scale: 1;
    top: -16rem;
  }
}

.deviceMedia {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--device-width);
  height: var(--device-height);
}

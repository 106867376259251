.container {
  composes: grid from '~g';
  position: absolute;
  z-index: var(--z-index-0);
  left: 0;
  width: 100%;
  height: 100%;
}

.mediaWrapper {
  position: relative;
  grid-column: 2 / span 4;

  @media (--md) {
    position: sticky;
    top: -50rem;
    height: 100vh;
    grid-column: 6 / span 4;
  }
}

.base,
.variant {
  /**
   * Note on the transforms here and the fixed values. This is a bit of a bespoke
   * moment and the values are driven by the arm asset and are used to align our
   * video to the the device cutout relative to the top/center of the image.
   *
   * If the arm asset updates, or the arm hotspot moves, these values need to
   * be adjusted. Currently the video asset does not have perfect aspect ratio
   * compared to the arm device cutout and can not be placed perfeclty within
   * the cutout region.
   */
  overflow: hidden;
  padding: 2px;
  border-radius: 4rem;
  background-color: black;
  transform: scale(0.58) translateX(2px) translateY(calc(4px + var(--img-scale) * 120px));
  transform-origin: top center;
  visibility: hidden;

  &.visible {
    z-index: 1;
    visibility: visible;
  }

  @media (--md) {
    border-radius: 3rem;
    transform: scale(0.8) translateX(2px) translateY(calc(var(--img-scale) * 50px));
  }

  @media (--lg) {
    transform: scale(0.8) translateX(2px) translateY(calc(var(--img-scale) * 50px));
  }
}

.base {
  visibility: hidden;
}

.variant {
  position: absolute;
}

.wrapper {
  position: absolute;
  z-index: var(--z-index-0, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.container {
  position: sticky;
  z-index: var(--z-index-0, 0);
  top: 0;
  width: 100%;
  height: 100vh;
  mix-blend-mode: screen;
}

.overlay {
  position: sticky;
  z-index: var(--z-index-1, 1);
  top: 0;
  width: 100%;
  height: 100vh;
  margin-top: -100vh;
  background: linear-gradient(rgb(0 0 0 / 0%) 60%, rgb(0 0 0 / var(--bottom-gradient-opacity, 0)) 85%);
}

.container {
  position: relative;

  /* use the scale of the image to control margin so that the stack container lines up with the devices */
  margin-top: calc(10rem + var(--img-scale) * 200rem);

  @media (--md) {
    /* align the stack container to the device container using the margin. this alignment allows both container to become sticky at the same time */
    margin-top: 54rem;
  }
}

.wrapper {
  position: relative;
  display: block;
}

.cardWrapper {
  @media (--md) {
    align-items: center;
  }
}
